import { Box, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Fragment } from "react";

export default function ProfileDetailBox(props) {

    const { data, sx, sizeTitle } = props;

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap',  mb: 0, ...sx, py: 2, gap : 2  }}>

            {data.map((item, index) => (

                <Fragment key={index} >
                    {item.value &&
                        <Box sx={{ 
                            display: 'flex', 
                            flexDirection: "column", 
                            width: { xs: '100%', md: 'auto' }, 
                            ...item.sx  
                            }}>

                            <Typography sx={{mb: 1, fontWeight: 'bold', fontSize: sizeTitle ?  sizeTitle +'px' : '14px', textAlign: 'start' }}>
                                {item.label}
                            </Typography>

                            <Box sx={{ backgroundColor: grey[100], borderRadius: '14px', p: 2 }}  >
                                {item.value}
                            </Box>
                        </Box>
                    }   
                
                </Fragment>
                
            ))}
        </Box>
    )
}
