
import { Box, Card, CardContent, Typography } from "@mui/material";
import { useEffect, useState } from "react";

import ExpertiseBox from "./ExpertiseBox";
import ProfileDetailBox from "./ProfileDetailBox";
import BlockBox from "./BlockBox";
import ServerProxy from "../tools/serverProxy";
import { MyDividerLarge, MyDividerSmall } from "./MyDivider";

export default function EntrepreneurCard(props) {

    const { title, idProfile } = props;
    const [data, setData] = useState(null);

    useEffect(() => {

        const loadData = async () => {


            try {
                const additionalInformation = await ServerProxy.getAdditionalInformation(idProfile);
                if (additionalInformation) {
                    const expertiseOnMap = new Map();

                    const espertiseAreas = await ServerProxy.getEspertiseAreas();
                    for (const item of espertiseAreas) {
                        expertiseOnMap.set(item.id, item.name);
                    }


                    const industryMap = new Map();
                    const companyFocus = await ServerProxy.getCompanyFocus();


                    for (const item of companyFocus) {
                        industryMap.set(item.id, item.name);
                    }

                    let expertiseOnSwap = additionalInformation.expertiseOn ? additionalInformation.expertiseOn.map((item) => expertiseOnMap.get(item.toString())) : null;

                    let knowAboutSwap = additionalInformation.knowAbout ? additionalInformation.knowAbout.map((item) => expertiseOnMap.get(item.toString())) : null;

                    let industrySwap = null;

                    if (additionalInformation.industry && additionalInformation.industry[0]) {
                        industrySwap = additionalInformation.industry.map((item) => industryMap.get(item.toString()));
                        if (industrySwap[0] == undefined) {
                            industrySwap[0] = additionalInformation.industry;
                        }
                    }

                    setData({
                        ...additionalInformation,
                        expertiseOn: expertiseOnSwap,
                        knowAbout: knowAboutSwap,
                        industry: industrySwap,
                    });

                }
                else {
                    setData(null);
                }


            } catch (error) {
                console.log(error);
            }
        }

        if (idProfile) {
            loadData();
        }
    }, [idProfile]);

    return (

        data && (

            <Card sx={{ width: '100%', paddingLeft: 0, paddingRight: 0, mt: 5, boxShadow: 3 }}>

                <CardContent>
                    <Typography sx={{ mb: 2, fontSize: '28px', fontWeight: 'bold' }} >{title}</Typography>

                    <MyDividerLarge />

                    <ExpertiseBox data={data} />

                    <MyDividerLarge />

                    <ProfileDetailBox data={[
                        {
                            label: 'Investment stage',
                            value: data.investmentStage && data.investmentStage.name && data.investmentStage.name,
                        }, {
                            label: 'Size',
                            value: data.companiesSize && data.companiesSize.name && data.companiesSize.name,
                        }, {
                            label: 'Founded',
                            value: data.founded,
                        }
                    ]} />

                    <MyDividerSmall />

                    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', py: 2, gap: 2 }}>
                        {data.industry && data.industry.length > 0 && (
                            <BlockBox title={'Industry: DEEPTECH'} data={data.industry} />
                        )}
                    </Box>

                </CardContent>
            </Card>
        )
    )
}
