import config from '../config';
import { styled, useTheme } from '@mui/material/styles';
import { Box, CssBaseline, Toolbar, List, Link, IconButton, Typography, Divider, Button, Avatar, CircularProgress, MenuItem, Menu, ListItemIcon, ListItemText, Badge, Tooltip, ListSubheader, ListItemButton, useMediaQuery } from '@mui/material';

import MuiAppBar from '@mui/material/AppBar';
import { Link as LinkRouter, Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { ChevronLeft, ChevronRight, Notifications, Help, KeyboardBackspace, JoinInner, Tune } from '@mui/icons-material';


import { strings } from '../strings.js'
import Home from './Home';
import { useEffect, useState } from 'react';

import DialogFilter from './tools/DialogFilter.js';
import BoxSx from './tools/BoxSx.js';
import UserCard from './UserCard.js';
import DialogCheckProfile from './tools/DialogCheckProfile.js';
import BestMatchDialog from './tools/BestMatchDialog.js';
import ServerProxy from './tools/serverProxy.js';
import MainToolBar from './tools/MainToolbar.js';
import { useLocation } from 'react-router-dom';
import RightDrawer from './tools/RightDrawer.js';
import LeftDrawer from './tools/LeftDrawer.js';
import HelpPanel from './tools/HelpPanel.js';
import MobileDashboard from './MobileDashboard.js';
import NoProfileModal from './NoProfileModal.js';


const drawerWidth = config.drawerWidth;
//const headerHeightConst = 285;


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })
	(({ theme, open }) => {

		if (open === undefined) return;

		const { leftOpen = true, rightOpen = true } = open;

		const intleft = leftOpen ? 1 : 0;
		const intRight = rightOpen ? 1 : 0;


		// console.log(intleft, intRight);

		const obj = {

			display: 'flex',
			flexDirection: 'column',
			height: '100vh',


			transition: theme.transitions.create('margin', {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),


			width: `calc(100% - ${drawerWidth * intleft + drawerWidth * intRight}px)`
		}


		if (intleft === 1 && intRight === 1) {


			obj.transition = theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			});

			obj.marginLeft = `-${drawerWidth}px`;
			obj.marginRight = `${drawerWidth}px`;


		}
		else if (intleft === 1 && intRight === 0) {

			obj.transition = theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			});

			obj.marginLeft = `-${drawerWidth}px`;
			obj.marginRight = 0;

		}
		else if (intleft === 0 && intRight === 1) {

			obj.transition = theme.transitions.create('margin', {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			});


			obj.marginLeft = `-${drawerWidth * 2}px`;
			obj.marginRight = `${drawerWidth}px`;

		}
		else {
			obj.marginLeft = `-${drawerWidth * 2}px`;
			obj.marginRight = 0;

			// obj.marginRight = 0;
			// obj.marginLeft = 0;
			// obj.width= '100%'
		}


		return obj;

	});

const AppBar = styled(MuiAppBar, { shouldForwardProp: (prop) => prop !== 'open' })
	(({ theme, open }) => {

		if (open === undefined) return;

		const { leftOpen = true, rightOpen = true } = open;

		const intleft = leftOpen ? 1 : 0;
		const intRight = rightOpen ? 1 : 0;

		//console.log(intleft, intRight);

		const obj = {
			transition: theme.transitions.create(['margin', 'width'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
		}

		if (intleft === 1 && intRight === 1) {

			obj.width = `calc(100% - ${drawerWidth * intleft + drawerWidth * intRight}px)`;
			obj.marginRight = drawerWidth;
			obj.marginLeft = drawerWidth;
			obj.transition = theme.transitions.create(['margin', 'width'], {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			});

		}
		else if (intleft === 1 && intRight === 0) {

			obj.width = `calc(100% - ${drawerWidth * intleft + drawerWidth * intRight}px)`;
			obj.marginLeft = drawerWidth;
			obj.transition = theme.transitions.create(['margin', 'width'], {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			});
		}
		else if (intleft === 0 && intRight === 1) {

			obj.width = `calc(100% - ${drawerWidth * intleft + drawerWidth * intRight}px)`;
			obj.marginRight = drawerWidth;
			obj.transition = theme.transitions.create(['margin', 'width'], {
				easing: theme.transitions.easing.easeOut,
				duration: theme.transitions.duration.enteringScreen,
			});
		}
		else {
			obj.marginRight = 0;
			obj.marginLeft = 0;
			obj.width = '100%'
		}


		return obj;

		// return ({



		// 	marginRight: drawerWidth,
		// 	width: `calc(100% - ${drawerWidth}px)`,

		// 	...( intleft === 1 && {
		// 		width: `calc(100% - ${drawerWidth * intleft + drawerWidth * intRight}px)`,

		// 		marginLeft: drawerWidth,


		// 		transition: theme.transitions.create(['margin', 'width'], {
		// 			easing: theme.transitions.easing.easeOut,
		// 			duration: theme.transitions.duration.enteringScreen,
		// 		}),
		// 	}),

		// 	...( intRight === 1 && {
		// 		width: `calc(100% - ${drawerWidth * intleft + drawerWidth * intRight}px)`,

		// 		marginLeft: drawerWidth,


		// 		transition: theme.transitions.create(['margin', 'width'], {
		// 			easing: theme.transitions.easing.easeOut,
		// 			duration: theme.transitions.duration.enteringScreen,
		// 		}),
		// 	}),

		// })
	});




const TitleLink = styled(Link)(({ theme }) => ({
	color: '#fff',
	'&:hover': {
		color: 'eee',
		textDecoration: 'none'
	},
}));


const ColorButton = styled(Button)(({ theme }) => ({
	color: theme.palette.primary.contrastText,
	backgroundColor: theme.palette.primary.main,
	textTransform: 'capitalize',
	borderRadius: 35,
	'&:hover': {
		backgroundColor: 'rgba(50, 50, 50,.1)'
	}
}));


function Dashboard() {

	const appTitle = 'Matchmaking Map'

	const theme = useTheme();

	const isSmallScreenPredefined = useMediaQuery(theme.breakpoints.down('md'));
	const isLargeScreenPredefined = useMediaQuery(theme.breakpoints.up('lg'));


	const [leftOpen, setLeftOpen] = useState(true);
	const [rightOpen, setRightOpen] = useState(isLargeScreenPredefined);
	const [topOpen, setTopOpen] = useState(true);

	// const [headerHeight, setHeaderHeight] = useState(config.headerHeight);

	const [openDialogFilter, setOpenDialogFilter] = useState(false);
	const [filters, setFilters] = useState(undefined);

	const [user, setUser] = useState(undefined);
	const [profile, setProfile] = useState(undefined);
	const [favorites, setFavorites] = useState(undefined);

	const [data, setData] = useState(undefined);
	const [textSearch, setTextSearch] = useState(null);

	const [anchorEl, setAnchorEl] = useState(null);
	const [notificsAnchorEl, setNotificsAnchorEl] = useState(null);

	const [openDialogBestMatch, setOpenDialogBestMatch] = useState(false);
	const [checkProfile, setCheckProfile] = useState(true);

	const [flyPosition, setFlyPosition] = useState(null);

	const [noProfiles, setNoProfiles] = useState(false);

	const [bestMatches, setBestMatches] = useState(null)


	const navigate = useNavigate();

	const location = useLocation();

	const [helpPanel, setHelpPanel] = useState(0);

	const onExit = () => {
		sessionStorage.clear();
		return window.location.assign(config.VES_URL);
	}

	const onOpenFilter = () => {
		setOpenDialogFilter(true);
	}

	const onCloseFilter = () => {

		setOpenDialogFilter(false);
		navigate('/home');
	}


	function onClickAction(item) {
		if (item) {
			navigate('/user/' + item.user.id + '/profile/' + item.id)
		}
	}

	const removeFavorite = async (item) => {

		try {

			await ServerProxy.removeFavorite(profile.id, item.id);
			setFavorites(favorites.filter((i) => i.id !== item.id));

		} catch (error) {
			console.log(error);
		}

	}

	const navigateToProfile = async (user, profile) => {
		await ServerProxy.saveClick(user.id, profile.id);
		navigate('/user/' + profile.user.id + '/profile/' + profile.id)
	}

	const addFavorite = async (item) => {

		try {

			await ServerProxy.addFavorite(profile.id, item.id);

			setFavorites([...favorites, item]);


			//setFavorites(favorites.filter((i) => i.id !== item.id));

		} catch (error) {
			console.log(error);
		}

	}

	const handleTextSearchSave = (text) => {
		sessionStorage.setItem('search', text);
		return setTextSearch(text)
	}

	const handleNoProfilesClose = (event, reason) => {
		if (reason && reason == 'backdropClick') return;
	}


	const flyTo = (position) => {
		setFlyPosition(position);
	}


	useEffect(() => {

		const loadData = async () => {

			try {

				const userId = sessionStorage.getItem('user') ? JSON.parse(sessionStorage.getItem('user')).id : null;
				const user = await ServerProxy.getUser(userId);

				if (user?.profiles.length === 0) {
					setNoProfiles(true);
					return
				}

				setUser(user);

			} catch (error) {
				console.log(error);
				return alert('Error loading filters: ' + error);
			}
		}

		loadData();
		//adjustHeigt();

		setTextSearch(sessionStorage.getItem('search') ?? null)

	}, []);

	useEffect(() => {

		if (!user) return;

		if (filters && filters.profile) {
			setProfile(filters.profile);
		} else if (JSON.parse(localStorage.getItem('profile'))) {
			setProfile(JSON.parse(localStorage.getItem('profile')));
		} else {
			let profile = user.profiles.find(item => item.defaultProfile === 1);

			if (!profile) {
				profile = user.profiles[0];
			}

			setProfile(profile);
		}

	}, [user]);



	useEffect(() => {


		async function loadData(profileId) {

			let favorites = null;
			//const liked = null;

			try {
				favorites = await ServerProxy.getFavorites(profileId);
			} catch (error) {
				console.log(error);
				favorites = [];
			}

			setFavorites(favorites);
		}



		if (profile) {
			if (sessionStorage.getItem('filters')) {
				setFilters(JSON.parse(sessionStorage.getItem('filters')));
			} else {

				const regions = profile.positions.map(({ region }) => region.id);
				const lookingFor = profile.lookingFor.map(({ id }) => id);
				let { knowAbout = [], expertiseOn = [] } = profile.additionalInfo || {};
				if(!knowAbout) knowAbout = [];
				if(!expertiseOn) expertiseOn = [];

				const oneMonthAgo = new Date();
				oneMonthAgo.setMonth(oneMonthAgo.getMonth() + 1);

				const f = {
					tabs: 0,
					where: regions,
					type: lookingFor,
					knowAbout: knowAbout,
					expertise: expertiseOn,
					startDate: new Date().toISOString().slice(0, -8),
					endDate: oneMonthAgo.toISOString().slice(0, -8),
					profile
				};

				setFilters(f);
			}
			loadData(profile.id);
		}

	}, [profile]);

	useEffect(() => {

		const loadData = async () => {

			// const oneMonthAgo = new Date();
			// oneMonthAgo.setMonth(oneMonthAgo.getMonth() + 1);

			// const { startDate=new Date() , endDate = oneMonthAgo } = filters;



			const matchProfiles = await ServerProxy.getMatches(
				//filters.tabs == 0 ? 'matchProfiles' : 'matchEvents',
				'matchProfiles',
				profile.id,
				{
					regions: filters.where,
					lookingFor: filters.type,
					expertise: filters.expertise
				},
				['user', 'profileMacroType', 'positions']
			);

			const matchEvents = await ServerProxy.getMatches(
				'matchEvents',
				profile.id,
				{
					regions: filters.where,
					lookingFor: filters.type,
					expertise: filters.expertise,
					startDate: filters.startDate,
					endDate: filters.endDate
				},
				['user', 'profileMacroType', 'positions']
			);


			setData({ profiles: matchProfiles, events: matchEvents });
		}

		if (filters === undefined || profile === undefined) return;

		loadData();

	}, [filters]);


	useEffect(() => {
		if (!data || !profile) return;
		const loadData = async () => {
			try {

				const profiles = await ServerProxy.getProfiles({ ids: profile.lastMatch.map((item) => item.id) });

				if (profiles) {
					setBestMatches(profiles.map((item) => (
						{
							...item,
							match: profile.lastMatch.find((match) => match.id === item.id).match
						}
					)));
					return;
				}
				setBestMatches([])

			} catch (error) {
				console.log(error);
			}
		}

		if (profile.lastMatch) {
			loadData();
		} else if (data) {
			setBestMatches([...data.profiles].slice(0, 3))
		}
	}, [data, profile])

	function getData() {
		if (textSearch) {
			return (data && data.profiles) ? data.profiles.filter((item) => item.user.firstname.toLowerCase().includes(textSearch.toLowerCase()) || item.user.lastname.toLowerCase().includes(textSearch.toLowerCase())) : [];
		}

		return (data && data.profiles) ? data.profiles : [];
	}


	function getEvents() {
		if (textSearch) {
			return (data && data.events) ? data.events.filter((item) => item.user.firstname.toLowerCase().includes(textSearch.toLowerCase()) || item.user.lastname.toLowerCase().includes(textSearch.toLowerCase())) : [];
		}

		return (data && data.events) ? data.events : [];
	}



	return (
		<>
			{noProfiles && <NoProfileModal noProfiles={noProfiles} handleNoProfilesClose={handleNoProfilesClose} navigate={navigate} />}

			{(user && profile && filters && getData()) ? (

				isSmallScreenPredefined ?

					<MobileDashboard

						addFavorite={addFavorite}

						data={getData()}
						events={getEvents()}
						favorites={favorites}
						filters={filters}
						helpPanel={helpPanel}

						navigateToProfile={navigateToProfile}


						onClickAction={onClickAction}
						onCloseFilter={onCloseFilter}

						onExit={onExit}
						onOpenFilter={onOpenFilter}
						onSearch={(text) => handleTextSearchSave(text)}

						openDialogBestMatch={openDialogBestMatch}
						openDialogFilter={openDialogFilter}


						profile={profile}

						removeFavorite={removeFavorite}
						bestMatches={bestMatches}

						setOpenDialogFilter={setOpenDialogFilter}
						setOpenDialogBestMatch={setOpenDialogBestMatch}

						setFilters={setFilters}
						setHelpPanel={setHelpPanel}
						setProfile={setProfile}

						user={user}


					/> :

					<>
						<CssBaseline />

						<HelpPanel open={helpPanel} setHelpPanel={setHelpPanel} setLeftOpen={setLeftOpen} setRightOpen={setRightOpen} />
						<BestMatchDialog profile={profile} bestMatches={bestMatches} open={openDialogBestMatch} navigateToProfile={navigateToProfile} handleClose={() => { setOpenDialogBestMatch(false) }} />
						<DialogCheckProfile open={profile.additionalInfo === null && checkProfile} handleClose={() => setCheckProfile(false)} onGoNow={onExit} />
						<DialogFilter open={openDialogFilter} handleClose={onCloseFilter} filters={filters} profile={profile} setOpenDialogFilter={setOpenDialogFilter} />

						<Box sx={{ display: 'flex', justifyContent: 'stretch', height: '100%' }}>

							<AppBar sx={{ zIndex: helpPanel === 9 ? 9999 : 1000 }} open={{ leftOpen, rightOpen }}>
								<Toolbar >
									<Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} >
										<Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }} >

											<IconButton
												color="inherit"
												aria-label="open drawer"
												onClick={() => {
													setLeftOpen(true);

													if (!isLargeScreenPredefined) {
														setRightOpen(false)
													}
												}}

												edge="start"
												sx={{ mr: 2, ...(leftOpen && { display: 'none' }) }}
											>
												<ChevronRight />
											</IconButton>

											<KeyboardBackspace sx={{ mr: 1 }} />

											<TitleLink sx={{ color: '#fff' }} href={config.accelerActionUrl}>
												Back to homepage
											</TitleLink>

											<Divider orientation="vertical" variant="middle" flexItem light sx={{ mx: 2, my: 1.5, bgcolor: '#fff' }} />


											<Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1, display: { xs: 'none', sm: 'block' } }}>
												<TitleLink fontWeight={700} component={LinkRouter} to={`/home`}>
													{appTitle}
												</TitleLink>
											</Typography>
										</Box>


										<Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', }} >

											{profile.additionalInfo === null && (
												<>
													<IconButton color="inherit" aria-label="OpenFilter" onClick={(e) => setNotificsAnchorEl(e.currentTarget)} >
														<Tooltip title="Notifications" placement="bottom">
															<Badge color="info" variant="dot">
																<Notifications />
															</Badge>
														</Tooltip>
													</IconButton>

													<Menu
														anchorEl={notificsAnchorEl}
														open={Boolean(notificsAnchorEl)}
														onClose={e => setNotificsAnchorEl(null)}
														dense
													>

														<List
															sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
															component="nav"
															aria-labelledby="nested-list-subheader"
															subheader={
																<ListSubheader component="div" id="nested-list-subheader">
																	<Typography sx={{ fontWeight: 'bold' }} variant='subtitle2' >Notification</Typography>
																</ListSubheader>
															}>


															<ListItemButton onClick={onExit} >
																<Avatar sx={{ bgcolor: '#eee', width: 48, height: 48, mr: 2 }} >
																	<Box component='img' sx={{ width: 24 }} src="/vector.svg" alt="Notifications" />
																</Avatar>
																<Typography variant='body2'>Complete your profile for a more accurate match</Typography>
															</ListItemButton>


														</List>
													</Menu>
												</>
											)}



											<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

												<ColorButton onClick={(event) => setAnchorEl(event.currentTarget)} variant="text">
													<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
														<Tooltip title={user.firstname + ' ' + user.lastname} >
															<Avatar sx={{ border: '2px solid #fff', mr: { xs: 0, sm: 1 } }} alt={user.firstname + ' ' + user.lastname} src={user.avatarUrl ? config.server.backetUrl + user.avatarUrl : null}>{user.firstname.charAt(0)}</Avatar>
														</Tooltip>
														<Box sx={{ display: { xs: 'none', sm: 'flex' }, justifyContent: 'center', alignItems: 'center' }}>{user.firstname}</Box>
													</Box>
												</ColorButton>

												<Menu
													anchorEl={anchorEl}
													open={Boolean(anchorEl)}
													onClose={e => setAnchorEl(null)}
													dense
												>

													<MenuItem
														sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
														onClick={onExit}
													>
														<ListItemIcon>
															<Tooltip title={user.firstname + ' ' + user.lastname} >
																<Avatar sx={{ width: 28, height: 28, mr: { xs: 0, sm: 1 } }} alt={user.firstname + ' ' + user.lastname} src={user.avatarUrl ? config.server.backetUrl + user.avatarUrl : null}>{user.firstname.charAt(0)}</Avatar>
															</Tooltip>
														</ListItemIcon>
														<ListItemText>Go to your profile</ListItemText>

													</MenuItem>

													<Divider />

													<MenuItem
														sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
														onClick={() => { setAnchorEl(null); setOpenDialogBestMatch(true) }}
													>
														<ListItemIcon>
															<JoinInner color="primary" />
														</ListItemIcon>
														<ListItemText>Your best matches</ListItemText>
													</MenuItem>

													<MenuItem
														sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
														onClick={() => { setAnchorEl(null); onOpenFilter() }}
													>
														<ListItemIcon>
															<Tune color="primary" />
														</ListItemIcon>
														<ListItemText>Manual filters</ListItemText>
													</MenuItem>

													{location.pathname === '/home' &&
														<MenuItem
															sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}
															onClick={() => { setAnchorEl(null); setHelpPanel(1) }}
														>
															<ListItemIcon>
																<Help color="primary" />
															</ListItemIcon>
															<ListItemText>Help</ListItemText>
														</MenuItem>
													}



													<Divider />

													{user.profiles.map((item, index) => (
														<MenuItem key={index}

															onClick={() => {
																localStorage.setItem('profile', JSON.stringify(item));
																setProfile(item);
																setAnchorEl(null)
																navigate('/home');
															}}

															sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}
														>

															<Typography sx={{ mr: 1, color: item.id === profile.id ? 'primary.main' : 'inherit' }} >{item.name}</Typography>
															<BoxSx>{item.profileMacroType.name}</BoxSx>

														</MenuItem>
													))}

												</Menu>

											</Box>

											<IconButton
												color="inherit"
												aria-label="open drawer"
												onClick={
													() => {
														setRightOpen(true)

														if (!isLargeScreenPredefined) {
															setLeftOpen(false)
														}
													}

												}
												edge="start"
												sx={{ ml: 2, ...(rightOpen && { display: 'none' }) }}
											>
												<ChevronLeft />
											</IconButton>

										</Box>

									</Box>
								</Toolbar>
							</AppBar>

							<LeftDrawer
								helpPanel={helpPanel}
								profile={profile}
								user={user}
								favorites={favorites}
								navigateToProfile={navigateToProfile}
								removeFavorite={removeFavorite} addFavorite={addFavorite} open={leftOpen} setLeftOpen={setLeftOpen} drawerWidth={drawerWidth}
								flyTo={flyTo}
								data={getData()}
							/>
							<RightDrawer helpPanel={helpPanel} profile={profile} favorites={favorites} events={getEvents()} flyTo={flyTo} removeFavorite={removeFavorite} open={rightOpen} setRightOpen={setRightOpen} drawerWidth={drawerWidth} />


							<Main sx={{ width: '100%' }} open={{ leftOpen, rightOpen }} >

								{location.pathname === '/home' &&
									<MainToolBar
										open={topOpen}
										setOpen={setTopOpen}
										helpPanel={helpPanel}
										defaultData={bestMatches}
										setOpenDialogBestMatch={setOpenDialogBestMatch}
										onOpenFilter={onOpenFilter}
										onSearch={(text) => handleTextSearchSave(text)}
										setHelpPanel={setHelpPanel}
									/>
								}

								<Box sx={{ flex: 1, display: 'flex' }} >

									<Routes>

										<Route path="/home" element={<Home
											// headerHeight={headerHeight} 
											profile={profile}
											drawerStatus={leftOpen}
											filters={filters}
											openDialogFilter={openDialogFilter}
											data={getData()}
											onClickAction={onClickAction}
											navigateToProfile={navigateToProfile}
											flyPosition={flyPosition}
											openView={{ topOpen, leftOpen, rightOpen }}

										/>} />

										<Route path='/user/:id/profile/:idProfile' element={<UserCard flyTo={flyTo} />}></Route>

										{/* <Route path='/login' element={<Login />} /> */}

										<Route path="/" element={<Navigate to="/home" replace />} />
										<Route path="/*" element={<Typography paragraph>{strings.pageNotFound}</Typography>} />
									</Routes>
								</Box>
							</Main>



						</Box>
					</>

			) : (
				<Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }} >
					<CircularProgress />
				</Box>
			)}
		</>
	)
}

export default Dashboard;
