import React, { useEffect, useRef, useState } from "react";

import ReactMapGL, { FullscreenControl, GeolocateControl, MapProvider, Marker, NavigationControl } from "react-map-gl";


import 'mapbox-gl/dist/mapbox-gl.css';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax

import AlertDialog from "./tools/AlertDialog";
import { Avatar, AvatarGroup, Box, Tooltip, tooltipClasses } from "@mui/material";
import config from "../config";
import md5 from "md5";

import { styled, useTheme } from '@mui/material/styles';


mapboxgl.accessToken = config.mapboxgl_accessToken;

const zoom = 2.9;

// ws_35: Attenzione, non mi assumo alcuna responsabilità per qualunque orrore di inglese che troverete in questo codice. Tutta colpa di Antonio

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'transparent',
        width: 340
    },
}));


function getKeyFromCoordinates(latitude, longitude) {
    return md5(Math.round(latitude).toString() + '-' + Math.round(longitude).toString());
}



export default function Home(props) {

    const { filters, data, mobile = false, openView, profile, navigateToProfile } = props;

    const [showPopup, setShowPopup] = useState(null);
    const [markers, setMarkers] = useState(undefined);
    const [groupView, setGroupView] = useState(localStorage.getItem('groupView') ?? 1);


    const position = {
        latitude: 37.51475005232238,
        longitude: 15.105983211392799
    };


    const mapRef = useRef();
    const mapContainerRef = useRef();

    const theme = useTheme();

    function onMarkerClickAction(item) {

        if (mapRef.current) {

            let zoom = mapRef.current.getZoom();

            if (item.type === 'region') {
                zoom = 2.9
            }
            else if (item.type === 'country') {
                zoom = 4.4
            }

            // else {
            //     console.log(item);
            // }

            mapRef.current.flyTo({ zoom, center: [item.longitude, item.latitude] });
        }
    }




    // function CircleMarker(props) {

    //     const { children, value, index } = props;

    //     const classname = {
    //         width: 30,
    //         height: 30,
    //         fontWeight: 'bold',
    //         bgcolor: '#f00',
    //         color: '#fff',
    //         borderRadius: '50%',
    //         display: 'flex',
    //         justifyContent: "center",
    //         alignItems: "center"
    //     };

    //     return (
    //         <Box sx={classname}>{children}</Box>
    //     )
    // }


    function CustomMarker(props) {

        const { marker, filters } = props;
        const avatatarStyles = { width: 26, height: 26, border: '1px solid #aaa', cursor: 'pointer' }


        const MarkerContent = function (props) {

            const { marker } = props;

            const title = marker.firstname ? marker.firstname : marker.profile ? marker.profile.user.firstname + ' ' + marker.profile.user.lastname : null;

            return (
                <Tooltip title={title}>
                    <AvatarGroup total={marker.size}>
                        <Avatar sx={{ ...avatatarStyles, }}
                            alt={marker.firstname + ' ' + marker.lastname}
                            src={marker.avatarUrl ? config.server.backetUrl + marker.avatarUrl : null}
                        />
                    </AvatarGroup>
                </Tooltip>
            )
        }


        return (

            <Marker

                onClick={e => {

                    if (marker.type) {

                        if (marker.type === 'place') { // caso multi markers
                            setShowPopup(marker);
                        }
                        else {
                            onMarkerClickAction(marker);
                        }
                    }
                    else { // caso singolo marker
                        setShowPopup(marker);
                    }

                }}

                longitude={marker.longitude}
                latitude={marker.latitude}
                anchor="center"
            >
                <MarkerContent marker={marker} />
            </Marker>
        )


    }

    // Funzione per calcolare la dimensione del raggruppamento in base al fattore di zoom
    function calculateGroupingSize(zoom) {
        // Esempio di implementazione, puoi personalizzarlo in base alle tue esigenze
        // Più alto è il valore di zoom, più piccola sarà la dimensione del raggruppamento
        return Math.pow(2, 12 - zoom);
    }


    function adjustZomm(z) {
        //zoom = z ;

        //localStorage.setItem('zoom', z);

        if (z < 2.8) {
            //console.log( "zoom" , zoom, calculateGroupingSize( zoom) );
            localStorage.setItem('groupView', 2)
            setGroupView("2")
        }
        else if (z < 4.2) {
            //console.log( "zoom" , zoom, calculateGroupingSize( zoom) );
            localStorage.setItem('groupView', 1)
            setGroupView("1")
        }
        else {
            localStorage.setItem('groupView', 0)
            setGroupView("0")
        }
    }


    useEffect(() => {

        if (mapRef.current) {
            mapRef.current.resize();
        }

        var geolocate = new mapboxgl.GeolocateControl();

        geolocate.on('geolocate', function (e) {
            console.log('geolocate', e);
        });


    }, [])


    useEffect(() => {

        setMarkers([]);

        if (data) {


            if (groupView === "1") { //country
                const groupedPoints = {};

                if (filters.tabs == 0) {
                    data.forEach(profile => {

                        const point = profile.positions[0];
                        if (point && point.country) {
                            const groupKey = point.country.code;

                            if (!groupedPoints[groupKey]) {
                                groupedPoints[groupKey] = [];
                            }

                            groupedPoints[groupKey].push(point);
                        }

                        else {
                            console.log('no country', profile);
                            return;
                        }

                    })
                }
                else {

                    data.forEach(userEvent => {

                        const point = userEvent.position;

                        if (point && point.country) {

                            const groupKey = point.country.code;

                            if (!groupedPoints[groupKey]) {
                                groupedPoints[groupKey] = [];
                            }

                            groupedPoints[groupKey].push(point);
                        }
                        else {
                            console.log('no country', userEvent);
                            return;
                        }

                    })
                }

                const d = [];

                for (const key in groupedPoints) {
                    if (Object.hasOwnProperty.call(groupedPoints, key)) {
                        const elements = groupedPoints[key];
                        d.push({
                            type: 'country',
                            firstname: elements[0].country.code,
                            latitude: elements[0].country.coordinates[0],
                            longitude: elements[0].country.coordinates[1],
                            avatarUrl: elements[0].avatarUrl,
                            //element     : elements[0],
                            key,
                            size: elements.length
                        })
                    }
                }

                setMarkers(d);
            }

            else if (groupView === "2") { //region 

                const groupedPoints = {};

                if (filters.tabs == 0) {
                    data.forEach(profile => {

                        const point = profile.positions[0];

                        if (point && point.region) {
                            const groupKey = point.region.id;

                            if (!groupedPoints[groupKey]) {
                                groupedPoints[groupKey] = [];
                            }

                            groupedPoints[groupKey].push(point);
                        }
                        else {
                            console.log('no region', profile);
                            return;
                        }

                    })
                }
                else {
                    data.forEach(userEvent => {
                        const point = userEvent.position;

                        if (point && point.region) {
                            const groupKey = point.region.id;

                            if (!groupedPoints[groupKey]) {
                                groupedPoints[groupKey] = [];
                            }

                            groupedPoints[groupKey].push(point);
                        }
                        else {
                            console.log('no region', userEvent);
                            return;
                        }

                    })
                }


                const d = [];

                for (const key in groupedPoints) {
                    if (Object.hasOwnProperty.call(groupedPoints, key)) {
                        const elements = groupedPoints[key];
                        d.push({
                            type: 'region',
                            firstname: elements[0].region.name,
                            latitude: elements[0].region.coordinates.latitude,
                            longitude: elements[0].region.coordinates.longitude,
                            avatarUrl: elements[0].avatarUrl,
                            //element     : elements[0],
                            key,
                            size: elements.length
                        })
                    }
                }


                //console.log(groupView, d);
                setMarkers(d);
            }

            else { //place 


                const groupedPoints = {};

                if (filters.tabs == 0) {

                    data.forEach(profile => {
                        const point = profile.positions[0];
                        const groupKey = getKeyFromCoordinates(point.latitude, point.longitude);

                        point.profile = profile;
                        point.avatarUrl = profile.user.avatarUrl;
                        point.profileMacroType = profile.profileMacroType.name;
                        point.shortDescription = profile.shortDescription;

                        if (!groupedPoints[groupKey]) {
                            groupedPoints[groupKey] = [];
                        }

                        groupedPoints[groupKey].push(point);

                    })
                }
                else {

                    data.forEach(userEvent => {

                        const point = userEvent.position;

                        const groupKey = getKeyFromCoordinates(point.latitude, point.longitude);
                        point.event = { ...userEvent };
                        point.user = userEvent.user;
                        point.avatarUrl = userEvent.user.avatarUrl;

                        if (!groupedPoints[groupKey]) {
                            groupedPoints[groupKey] = [];
                        }

                        groupedPoints[groupKey].push(point);

                    })
                }


                const d = [];

                for (const key in groupedPoints) {

                    if (Object.hasOwnProperty.call(groupedPoints, key)) {
                        const elements = groupedPoints[key];

                        if (elements.length > 1) {
                            d.push({
                                type: 'place',
                                firstname: elements[0].place,
                                place: elements[0].place,
                                latitude: elements[0].latitude,
                                longitude: elements[0].longitude,
                                avatarUrl: elements[0].avatarUrl,
                                elements: elements,
                                key,
                                size: elements.length
                            })
                        }

                        else {
                            d.push(elements[0]);
                        }
                    }
                }
                setMarkers(d);
            }
        }

        if (mapContainerRef.current) {

            const resizeObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    //const { width } = entry.contentRect;

                    if (mapRef.current) {
                        mapRef.current.resize();
                    }
                }
            });

            // Observe the map container for width changes
            resizeObserver.observe(mapContainerRef.current);

            return () => {
                // Clean up the observer when the component unmounts
                resizeObserver.disconnect();
            };
        }

    }, [data, groupView])


    useEffect(() => {

        if (props.flyPosition && mapRef.current !== undefined) {
            mapRef.current.flyTo({ zoom: 8, center: [props.flyPosition.longitude, props.flyPosition.latitude] });
        }

    }, [props.flyPosition]);


    useEffect(() => {
        if (openView && mapRef.current && (!openView.topOpen || !openView.rightOpen || !openView.leftOpen)) {

            setTimeout(() => {
                mapRef.current.resize();
                mapRef.current.triggerRepaint()
            }, 500);

        }

    }, [openView]);

    if (markers === undefined) {
        return null;
    }


    return (

        <Box ref={mapContainerRef} sx={{ flex: 1, width: "100%", border: `1px solid ${theme.palette.primary.dark}`, borderBottom: 'none' }} >
            <AlertDialog profile={profile} open={showPopup} handleClose={() => setShowPopup(null)} navigateToProfile={navigateToProfile} />

            <MapProvider >
                <ReactMapGL
                    ref={mapRef}
                    mapLib={mapboxgl}

                    initialViewState={
                        JSON.parse(localStorage.getItem('viewState')) ?? {
                            ...position,
                            zoom
                        }}
                    style={{
                        width: "100%"
                    }}

                    //mapStyle="https://api.maptiler.com/maps/openstreetmap/style.json?key=fTIyKxsYU1L62XwouQze"
                    // mapStyle="https://api.maptiler.com/maps/ocean/style.json?key=fTIyKxsYU1L62XwouQze"

                    mapStyle={"https://api.maptiler.com/maps/outdoor-v2/style.json?key=" + mapboxgl.accessToken}

                    onDblClick={() => { }}
                    onZoom={(e) => { adjustZomm(e.viewState.zoom) }}

                    onMove={(e) => { localStorage.setItem('viewState', JSON.stringify(e.viewState)); }}
                >

                    {markers && markers.map((marker, index) => (
                        <CustomMarker key={index} marker={marker} filters={filters} />
                    ))}


                    <GeolocateControl position={'top-right'} />
                    {/* {!mobile && <FullscreenControl position={'top-right'} />} */}
                    <NavigationControl visualizePitch={true} position={'top-right'} />


                </ReactMapGL>
            </MapProvider>

        </Box>

    );
}