import {  PriorityHigh, TripOrigin } from "@mui/icons-material";
import { Box, List, ListItem,  Typography } from "@mui/material";
import { useTheme} from '@mui/material/styles';
import { grey } from "@mui/material/colors";
import { Asterisk } from "../tools/Icon";


export default function ExpertiseBox(props) {

    const theme = useTheme();
    const color = theme.palette.primary.main;

    const { data } = props;

    const dataStatic = [
        {   
            key : 'expertiseOn',
            label : 'Expertise on'
        },{   
            key : 'knowAbout',
            label : 'Would like to know more about'
        }
    ]


    return (
        <Box sx={{ mb: 3}}> 

            <Box sx={{
                display: 'flex',
                alignItems: {
                    xs: 'center',
                    sm: 'flex-start',
                },
                flexDirection: 'row',
                flexWrap: 'wrap',
                gap: 2
            }}>
                {dataStatic.map((item, index) => (

                    <>
                    {data[item.key] && (
                        <Box key={index} sx={{ 
                            display: 'flex', 
                            flexDirection: "column", 
                            width: { xs: '100%', md: 'unset' }
                        }}>
                          
                            <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
                                <Asterisk color="primary" sx={{ mr: 1}} />

                                <Typography  sx={{ fontWeight: 700, fontStyle: 'normal', lineHeight: 'normal', fontSize: 18, textAlign: 'start', color: 'black' }}>
                                    {item.label}
                                </Typography>
                            </Box>


                            <Box sx={{ backgroundColor: grey[100], borderRadius: '14px' }}  >

                                <Box sx={{ display: 'flex', flexWrap: 'wrap', p: 0, m: 0, gap: 2 }}>

                                    <List>
                                        {data[item.key] && data[item.key].map((value, index) => (

                                            <ListItem sx={{m: 0, p: 1, py: .2 }} key={index}>
                                                <Box sx={{display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <TripOrigin sx={{ color, fontSize: '12px',  mr : .8 }} />    
                                                    <Typography variant="body2" sx={{ fontWeight: '400' }}>{value}</Typography>
                                                </Box>
                                            </ListItem>
                                        ))}

                                    </List>
                                </Box>
                            </Box>
                        </Box>
                   )} 
                   </>   
                ))}

            </Box>
        </Box>
    )
}
