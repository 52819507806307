import { ChevronLeft } from "@mui/icons-material";
import { styled } from '@mui/material/styles';
import { Box, CircularProgress, Divider, Drawer, IconButton, Link, List, Snackbar, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import DrawerListItem from "./DrawerListItem";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "./ConfirmModal";


const DrawerHeader = styled('div')(({ theme }) => ({

    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,

}));


export default function LeftDrawer(props) {

    const { open, setLeftOpen, drawerWidth, data, helpPanel, navigateToProfile } = props;

    const navigate = useNavigate();

    const [confirmMethod, setConfirmMethod] = useState({
        item: null,
        method: null
    });

    const [openSnackbar, setOpenSnackbar] = useState(false);



    return (

        <Drawer
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                    width: drawerWidth,
                    boxSizing: 'border-box',
                },

                zIndex: helpPanel === 2 ? 9999 : 'initial',

            }}

            variant="persistent"
            anchor="left"
            open={open}
        >
            <DrawerHeader>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>


                    <Box sx={{ flex: 1, display: 'flex', justifyContent: 'center' }} >
                        <Link href={config.accelerActionUrl}><Box component='img' src='/AccelerAction_Logo.svg' sx={{ mx: 'auto' }} /></Link>
                    </Box>

                    <Box>
                        <IconButton onClick={() => setLeftOpen(false)}>
                            <ChevronLeft />
                        </IconButton>
                    </Box>

                </Box>
            </DrawerHeader>

            <ConfirmModal confirmMethod={confirmMethod} setConfirmMethod={setConfirmMethod} />

            <Snackbar
                open={openSnackbar}
                autoHideDuration={5000}
                onClose={() => setOpenSnackbar(false)}
                message={`${openSnackbar} is added to favorites list`}
            />

            <Box sx={{ height: '100%', width: '100%', mb: 1, overflow: 'auto', maxWidth: `${drawerWidth}px` }}>

                {data ? (

                    <>
                        {data.length === 0 ? (
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', p: 5 }}>
                                <Typography variant="body1" color="text.secondary">No data yet</Typography>
                            </Box>
                        ) :
                            <List sx={{ width: '100%', bgcolor: 'background.paper', p: 1 }}>
                                {data.map((item, index) => (
                                    <Fragment key={index} >
                                        <DrawerListItem {...props} setConfirmMethod={setConfirmMethod} setOpenSnackbar={setOpenSnackbar} item={item} onClickAction={navigateToProfile} />
                                        <Divider sx={{ width: '10%', border: '1px solid #ddd', mx: 'auto', my: 2 }} />
                                    </Fragment>
                                ))}
                            </List>
                        }
                    </>

                ) : (
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', p: 5 }}>
                        <CircularProgress />
                    </Box>
                )}
            </Box>

            <Box sx={{ position: 'fixed', bottom: 0, width: drawerWidth, px: 1, py: .5, bgcolor: 'background.paper' }}>
                <Typography variant="caption" color="text.secondary">Your matches ({data.length})</Typography>
            </Box>

        </Drawer>
    )
}
