import LocalizedStrings from 'react-localization';
// import {enUS, itIT} from '@mui/x-data-grid'

export const strings = new LocalizedStrings({
	en: {
		userName: 'User name',
		password: 'Password',
		logIn: 'Log in',
		domains: 'Domains',
		servers: 'Servers',
		parameters: 'Parameters',
		settings: 'Settings',
		logout: 'Log out',
		standard: 'Standard',
		search: 'Search…',
		pageNotFound: 'Page not found.',
		changeStatus: 'Change status',
		statusChangeConfirmation: 'Click OK if you want to change the status of the domain.',
		cancel: 'Cancel',
		ok: 'OK',
		server: 'Server',
		type: 'Type',
		all: 'All',
		id: 'ID',
		name: 'Name',
		status: 'Status',
		expiryDate: 'Expiry date',
		filter: 'Filter',
		filters: 'Filters',
		showAsGrid: 'Show as a grid',
		showAsList: 'Show as a list',
		reload: 'Reload',
		add: 'Add',
		enabled: 'Enabled',
		disabled: 'Disabled',
		details: 'Details',
		wrongCommand: 'Wrong command.',
		deleteDomain: 'Delete the domain',
		domainDeletionConfirmation: 'The deletion of the domain is irreversible. If you are sure you want to delete the domain, type "delete domain".',
		delete: 'Delete',
		newDomain: 'New domain',
		back: 'Back',
		domainName: 'Domain name',
		database: 'Database',
		repository: 'Repository',
		sslTlsCertificate: 'SSL/TLS certificate',
		openOnTheWeb: 'Open on the Web',
		apply: 'Apply',
		publicIP: 'Public IP',
		privateIP: 'Private IP',
		stop: 'Stop',
		serverStopConfirmation: 'Are you sure you want to stop the server?',
		instanceID: 'Instance ID',
		instanceType: 'Instance type',
		cpu: 'CPU',
		cores: 'Core',
		threadsPerCore: 'Threads per core',
		root: 'Root',
		data: 'Data',
		started: 'Started',
		stopped: 'Stopped',
		startServer: 'Start the server',
		stopServer: 'Stop the server',
		value: 'Value',
		language: 'Language',
		default: 'Default',
		english: 'English',
		italian: 'Italian',
		theme: 'Theme',
		light: 'Light',
		dark: 'Dark',
		restartWebServer: 'Restart the Web server',
		noData: 'Nessun record',
		syncRepository : 'Sync code',
		deleteCache : 'Delete Cache',
		version : 'Version',
		host: 'Host',
		user: 'User',
		yes: 'Yes',
		no : 'No',
		reboot : 'Reboot',
		setOwn : 'Set own',
		engine : 'Engine',
		operations : 'Operations',
		gitUrl : 'Git url',
		dbHost : 'Db host',
		containers : 'Containers',
		npmInstall : 'Npm install',
		restart : 'Restart',
		home : 'Home',
		parametersPhp : 'Php parameters',
		where : 'Where',
		sectors : 'Sectors',
		resetFilters : 'Reset Filters',
		whatToLookFor : 'What to look for',
		actors : 'Actors',
		events : 'Events',
		startDate : 'Start date',
		endDate : 'End date',

	},
	it: {
		userName: 'Nome utente',
		password: 'Password',
		logIn: 'Entra',
		domains: 'Domini',
		servers: 'Server',
		parameters: 'Parametri',
		settings: 'Impostazioni',
		logout: 'Esci',
		standard: 'Standard',
		search: 'Cerca…',
		pageNotFound: 'Pagina non trovata',
		changeStatus: 'Cambia lo stato',
		statusChangeConfirmation: 'Clicca OK se vuoi cambiare lo stato del dominio.',
		cancel: 'Annulla',
		ok: 'OK',
		server: 'Server',
		type: 'Tipo',
		all: 'Tutti',
		id: 'ID',
		name: 'Nome',
		status: 'Stato',
		expiryDate: 'Data di scadenza',
		filter: 'Filtra',
		showAsGrid: 'Mostra come griglia',
		showAsList: 'Mostra come lista',
		reload: 'Ricarica',
		add: 'Aggiungi',
		enabled: 'Abilitato',
		disabled: 'Disabilitato',
		details: 'Dettagli',
		wrongCommand: 'Comando errato.',
		deleteDomain: 'Elimina il dominio',
		domainDeletionConfirmation: 'L\'eliminazione del dominio è irreversibile. Se sei sicuro/a di voler eliminare il dominio, digita "delete domain".',
		delete: 'Elimina',
		newDomain: 'Nuovo dominio',
		back: 'Indietro',
		domainName: 'Nome dominio',
		database: 'Database',
		repository: 'Repository',
		sslTlsCertificate: 'Certificato SSL/TLS',
		openOnTheWeb: 'Apri nel Web',
		apply: 'Applica',
		publicIP: 'IP pubblico',
		privateIP: 'IP privato',
		stop: 'Arresta',
		serverStopConfirmation: 'Sei sicuro/a di voler arrestare il server?',
		instanceID: 'ID istanza',
		instanceType: 'Tipo di istanza',
		cpu: 'CPU',
		cores: 'N. core',
		threadsPerCore: 'N. thread per core',
		root: 'Root',
		data: 'Dati',
		started: 'Avviato',
		stopped: 'Arrestato',
		startServer: 'Avvia il server',
		stopServer: 'Arresta il server',
		value: 'Valore',
		language: 'Lingua',
		default: 'Predefinito',
		english: 'Inglese',
		italian: 'Italiano',
		theme: 'Tema',
		light: 'Chiaro',
		dark: 'Scuro',
		restartWebServer: 'Riavvia il server Web',
		noData: 'Nessun record',
		syncRepository : 'Sync code',
		deleteCache : 'Delete Cache'
	}
})

// export const getDataGridLocale = () => {
// 	let locale
// 	switch (strings.getLanguage()) {
// 		case 'en':
// 			locale = enUS.components.MuiDataGrid.defaultProps.localeText
// 			break
// 		case 'it':
// 			locale = itIT.components.MuiDataGrid.defaultProps.localeText
// 			break
// 		default:
// 			locale = enUS.components.MuiDataGrid.defaultProps.localeText
// 	}

// 	return locale
// }
