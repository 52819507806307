import { Box, Button, Card, FormControl, FormControlLabel, FormLabel, InputAdornment, Link, Radio, RadioGroup, Stack, Switch, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import {useNavigate, useParams } from "react-router-dom";
import ServerProxy from "../tools/serverProxy";

export default function CardEvent(props) {

    const { idEvent = '0' } = useParams();

    const navigate = useNavigate();

    const [event, setEvent] = useState(null);


    useEffect(() => {

        async function loadData(idEvent) {

            const r = await ServerProxy.getEvent(idEvent);

            if (r.status !== 200) {
                return;
            }

            setEvent(r.data);
        }


        loadData(idEvent)

    }, [idEvent]);


    const onSaveAction = async () => {

        const data = await ServerProxy.updateEvent(event);

        console.log(data);

        if (data.status === 200) {
            navigate('/admin');
        }

    }

    const onCancelAction = () => {
        navigate('/admin');
    }


    return (

        <Box sx={{ p: 6 }}>
            {event && (
                <Box maxWidth="md" sx={{mx: 'auto'}} >
                    <Stack spacing={4}>

                        <FormControl>
                            <FormLabel id="demo-row-radio-buttons-group-label">Status</FormLabel>
                            <RadioGroup row 
                                name="status"  
                                value={event.status}
                                onChange={ e => setEvent({
                                    ...event,
                                    status: e.target.value
                                }) } >

                                <FormControlLabel value="0" control={<Radio />} label="Pending" />
                                <FormControlLabel value="1" control={<Radio />} label="Accepted" />
                                <FormControlLabel value="2" control={<Radio />} label="Rejected" />
                                <FormControlLabel value="3" control={<Radio />} label="To be approved" />
                               
                            </RadioGroup>
                        </FormControl>

                        <TextField
                            label="Name"
                            value={event.name}
                            onChange={ e => setEvent( {
                                ...event,
                                name: e.target.value
                            })}
                        />

                        <TextField
                            label="Description"
                            multiline
                            rows={4}
                            value={event.description}
                            onChange={ e => setEvent( {
                                ...event,
                                description: e.target.value
                            })}
                            />


                        <Stack direction="row" spacing={5}>
                            <TextField
                                sx={{ width: 200 }}
                                label="Date and time"
                                type="date"
                                value={ new Date(event.date_at).toISOString().split('T')[0] } 
                                onChange={ e => setEvent( {
                                    ...event,
                                    date_at: new Date(e.target.value).toISOString()
                                })}
                            />

                            <FormControlLabel control={<Switch 
                                onChange={ e => setEvent( {
                                    ...event,
                                    online: !event.online
                                })} 
                                checked={ event.online === 1 } />} 
                                label="Online" />
                        </Stack>



                        <TextField
                            label="Place"  
                           
                            disabled={ event.online === 1  }
                            value={event.place}
                            onChange={ e => setEvent( {
                                ...event,
                                place: e.target.value
                            })}

                            helperText={ event.position ? <Button sx={{}} target="_blank" component={Link} href={`https://www.google.com/maps/@${event.position.latitude},${event.position.longitude},9z?entry=ttu`} color="primary">Go to the map</Button> : null }

                            />

                        <TextField
                            label="Fee"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">€</InputAdornment>,
                            }}
                            sx={{ width: 290 }}
                            value={event.fee}
                            onChange={ e => setEvent( {
                                ...event,
                                fee: e.target.value
                            })}

                            />


                        <TextField
                            label="Link"
                            multiline
                            rows={4}
                            value={event.url}
                            onChange={ e => setEvent( {
                                ...event,
                                url: e.target.value
                            })}

                            helperText={ event.url ? <Button sx={{}} target="_blank" component={Link} href={event.url} color="primary">Go to URL</Button> : null }

                            />

                        <Stack justifyContent="flex-end" direction={'row'} spacing={2} > 

                            <Button variant="outlined" color="primary" onClick={ onCancelAction }>Cancel</Button>
                            <Button variant="contained" color="primary" onClick={ onSaveAction }>Save</Button>



                        </Stack>

                    </Stack>
                </Box>
            )}
        </Box>

    )
}