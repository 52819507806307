import {createTheme, ThemeProvider } from '@mui/material';
import Dashboard from './components/Dashboard';
import Login from './components/Login';
import { useEffect, useState } from 'react';
import { strings } from './strings';
import Admin from './components/admin/Admin';

import Poppins from './fonts/Poppins/Poppins-Regular.ttf';
import { useLocation } from 'react-router-dom';
import config from './config';


function App() {
	
	// const [browserTheme, setBrowserTheme] = useBrowserThemeDetector() 		

	const browserTheme = 'light';

	const appTheme = createTheme({

		palette: {
			mode: `${browserTheme}`,

			primary: {
				main: '#c0087f',
				light: '#D357A8',
				dark: '#76004D',
				moreDark: '#310020',
				contrastText: '#fff',
				text : '#000'

			},

			secondary: {
				main: '#dcb966',
				light: '#FFEDC4',
				dark: '#A68B00',
				contrastText: '#000',
			}
		},

		typography: {
			fontFamily: 'Poppins, sans-serif',
		},

		overrides: {
			MuiCssBaseline: {
				'@global': {
					'@font-face': [Poppins], // Define the font family
				},
			},
		},

		components: {
			MuiButton: {
				styleOverrides: {
					root: {
						borderRadius: '20px', // Imposta il border-radius per tutti i pulsanti
					},
				},
			},
		},

	});

	
	const [role, setRole ] = useState(undefined);

	const location = useLocation();


	useEffect(() => {
		strings.setLanguage('en');


		if (location.pathname.includes('/admin')) {
			setRole('admin');
		} else if (location.pathname.includes('/login')) {
			setRole('dashboard');
		}

		setRole(sessionStorage.getItem('role') ?? undefined)
		
	}, []);


	const token = JSON.parse(sessionStorage.getItem('token'));

	
	const Main = () => {
		if (role === 'dashboard') {
			if (token) {
				return <Dashboard />
			}

			return window.location.assign(config.VES_URL);
		}
		
		if (role === 'admin' && token) {
			return <Admin />
		}

		return <Login />
	} 


	return (
		<ThemeProvider theme={appTheme}>
			<Main />
		</ThemeProvider>
	)
}

export default App;
