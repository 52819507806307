import { Box, Modal, CssBaseline, Toolbar, List, Link, IconButton, Typography, Divider, Button, Avatar, CircularProgress, MenuItem, Menu, ListItemIcon, ListItemText, Badge, Tooltip, ListSubheader, ListItemButton, Dialog, DialogTitle, DialogContent, useTheme, useMediaQuery } from '@mui/material';
import config from '../config';

export default function NoProfileModal(props) {
    const { noProfiles, handleNoProfilesClose, navigate } = props
    const theme = useTheme();
    const fullWidth = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog
            fullWidth={true}
            maxWidth={fullWidth ? 'xs' : 'md'}
            open={noProfiles}
            onClose={handleNoProfilesClose}
            PaperProps={{
                style: { backgroundColor: 'rgba(255,255,255, .9)', padding: '16px' },
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', mb: 2 }}>
                <Box sx={{ width: '250px', mb: 2 }} component='img' src="/AccelerAction_Logo.png" alt='Logo' />

                <DialogTitle sx={{ textAlign: 'center', m: 0 }}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 'bold', mb: 1, fontSize: '24px' }} variant="h4" color="text.primary">Complete Your Profile to Get Started!</Typography>
                </DialogTitle>


                <Box id="no-profile-modal-description" sx={{ mt: 2, textAlign: 'center', fontSize: '1rem', mb: 4, px: 3 }}>
                    <Typography>It looks like you haven't created a profile yet.</Typography>
                    <Typography>To access all the features and make the most out of the platform, you'll need to set up your profile first.</Typography>
                    <Typography>Click the button below to go to the main page and get started!"</Typography>
                </Box>
                <Link href={config.VES_URL}>
                    <Button
                        sx={{ mt: 2, height: '50px', padding: { xs: "20px 30px", md: "15px 20px" }, fontSize: { xs: '1.1rem', sm: '1rem' }, borderRadius: '25px', background: 'linear-gradient(to left, rgb(196, 31, 117), rgb(236, 227, 20))', color: "white", transition: 'all 0.2s ease-in-out', boxShadow: '0 0 12px 5px rgba(0,0,0,0.31)', '&:hover': { color: "#c0087f" } }}
                    >
                        Back to Account
                    </Button>
                </Link>
            </Box>
        </Dialog >
    )
}