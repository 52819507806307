import { Divider } from "@mui/material";
import { styled} from "@mui/material/styles";


const MyDividerLarge = styled(Divider)(({ theme }) => ({
    height: '3px',
    backgroundColor: theme.palette.primary.main,
    marginBottom : '10px'
}));

const MyDividerSmall = styled(Divider)(({ theme }) => ({
    backgroundColor: theme.palette.grey[300],
    marginBottom : '10px'
}));


export {MyDividerLarge, MyDividerSmall} ;