import { Box, Button, Modal, Typography } from '@mui/material';
import { styled, useTheme} from '@mui/material/styles';




export default function ConfirmModal (props) {

    const theme = useTheme();
    
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: { xs : 320, sm : 400 },
        bgcolor: 'background.paper',
        border: `2px solid ${theme.palette.primary.main}`,
        boxShadow: 24,
        p: 4,
        textAlign:'center',
        borderRadius: 2,
    };


    const {confirmMethod, setConfirmMethod} = props

    const handleClose = () => setConfirmMethod({item: null, method: null});

    const onAction = async () => {
        await confirmMethod.method(confirmMethod.item);

        if ( confirmMethod.callback){
            confirmMethod.callback();
        }

        handleClose();
    }

    return (
        <Modal
            open={confirmMethod.item !== null}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography sx={{ fontSize: 28, fontWeight: 'bold'}} id="modal-modal-title" variant="button" component="h2">
                    Attention
                </Typography>
                <Typography id="modal-modal-description" sx={{  }}>
                    Are you sure you want to remove the profile from the list?
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'space-evenly', mt: 2 }}>
                    <Button sx={{px:4}}onClick={onAction} variant="contained" color="primary">Yes</Button>
                    <Button sx={{px:4}}onClick={handleClose} variant="contained" color="primary">No</Button>
                </Box>
            </Box>
        </Modal>
    )
}
