import { Link as LinkRouter } from 'react-router-dom';
import { Box, IconButton, Link, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { useEffect, useMemo, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Autorenew, CheckCircleOutline, Delete, Edit, MoreHoriz, NotInterested } from '@mui/icons-material';
import ServerProxy from '../tools/serverProxy';


export function GridEvents(props) {

    const { events } = props;

    const [data, setData] = useState([]);

    const [deleting, setDeleting] = useState(false);

    const [anchorEl, setAnchorEl] = useState({
        element: null,
        id: null
    });

    const openMenuStatus = Boolean(anchorEl.element);

    const onDeleteEvent = async (id) => {
        
        if (window.confirm(`Delete the event with id: ${id}?`) == true) {

            
            const t = await ServerProxy.deleteEvent(id);

            if (t.status === 200) {
                setDeleting(t.data.id)   ;

            }
        }
    }

    const onViewEvent = async (id) => {


    }

    const onChangeStatus = async (status) => {

        const r = await ServerProxy.updateEventStatus(anchorEl.id, status);

        if (r.status === 200) {

            const t = data.map((item) => {
                if (item.id === anchorEl.id) {
                    item.status = status;
                }
                return item;
            });

            setData(t);
        }

        setAnchorEl({element: null, id: null});

    }


    useEffect(() => {
        setData(events);
    }, [events]);


    useEffect(() => {
      
        if (deleting) {
            setData(data.filter((item) => item.id !== deleting));    
            setDeleting(false);
        }
    }, [deleting]);


    const getValue = (value) => {   
        switch (value) {
            case 0:
                return 'Pending';
            case 1:
                return 'Accepted';
            case 2:
                return 'Rejected';
            case 3:
                return 'To be approved';
            default:
                return 'Unknown';
        }
    }


    const columns = useMemo(() => {

        return [
            {
                field: 'id',
                headerName: 'ID',
                width: 60,
                hideable: false,

                renderCell: params => <Link component={LinkRouter} to={`/admin/${params.row.id}`}>{params.row.id}</Link>
            },

            {
                field: 'user',
                headerName: 'User',
                width: 200,
                
                sortable: false,
                // filterable: false,
                hideable: false,

                valueGetter : params => {
                    const { firstname, lastname } = params.value;
                    return `${firstname} ${lastname}`
                }           
            },


            {
                field: 'name',
                headerName: 'Name',
                width: 400,
                editable: false,
                renderCell: params => <Link title={params.row.name} component={LinkRouter} to={`/admin/${params.row.id}`}>{params.row.name}</Link>

            },
            {
                field: 'date_at',
                headerName: 'Date / Time',
                type: 'dateTime',
                width: 160,
                editable: false,
                valueGetter: params => new Date(params.value.slice(0, -8))
            },
            // {
            //     field: 'status',
            //     headerName: 'Status',
            //     renderCell: params => {

            //         const {value} = params;

            //         return (
            //             <Box title={getValue(value)} sx={{width: '100%', display: 'flex', justifyContent: 'center' }}>
            //                 <IconButton onClick={ e => setAnchorEl({element: e.currentTarget, id: params.row.id}) }>
            //                     { value === 0 && <MoreHoriz color="info" /> } 
            //                     { value === 1 && <CheckCircleOutline color="success" /> } 
            //                     { value === 2 && <NotInterested color="error" /> } 
            //                     { value === 3 && <Autorenew color="warning" /> }
            //                 </IconButton>
            //             </Box>
            //         )
            //     }
            // },
            {
                field: 'status',
                headerName: 'Operation',
                width: 100,
                sortable: false,
                filterable: false,
                hideable: false,
                renderCell: params => {

                    const {value} = params;

                    return (
                        <Box sx={{display: 'flex', width: '100%'}}>
                            <IconButton title={getValue(value)} onClick={ e => setAnchorEl({element: e.currentTarget, id: params.row.id}) }>
                                { value === 0 && <MoreHoriz color="info" /> } 
                                { value === 1 && <CheckCircleOutline color="success" /> } 
                                { value === 2 && <NotInterested color="error" /> } 
                                { value === 3 && <Autorenew color="warning" /> }
                            </IconButton>

                            <IconButton onClick={ () => onDeleteEvent(params.row.id) }>
                                <Delete color="error" />
                            </IconButton>

                        </Box>
                    )
                }
                
            }
        ];

    }, []);

    

    return (

        
        <Box sx={{ height: 'calc(100vh - 115px)', width: '100%' }}>

        <Menu
            id="basic-menu"
            anchorEl={anchorEl.element}
            open={openMenuStatus}
            onClose={() => setAnchorEl({element: null, id: null})}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
{/* 
{ value === 0 && <MoreHoriz color="info" /> } 
                                { value === 1 && <CheckCircleOutline color="success" /> } 
                                { value === 2 && <NotInterested color="error" /> } 
                                { value === 3 && <Autorenew color="warning" /> } */}

                <MenuItem onClick={() => onChangeStatus(1)}> 
                    <ListItemIcon>
                        <CheckCircleOutline color="success" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Accepted</ListItemText>
                </MenuItem>
                
                <MenuItem onClick={() => onChangeStatus(2)}> 
                    <ListItemIcon>
                        <NotInterested color="error" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>Rejected</ListItemText>
                </MenuItem>

                <MenuItem onClick={() => onChangeStatus(3)}> 
                    <ListItemIcon>
                        <Autorenew color="warning" fontSize="small" />
                    </ListItemIcon>
                    <ListItemText>To be approved</ListItemText>
                </MenuItem>
        </Menu>

            <DataGrid
                sx={{ height: '100%', width: '100%' }}
                rows={data}
                columns={columns}
                initialState={{
                   
                    pagination: {
                        paginationModel: {
                            pageSize: 25,
                        },
                    },
                }}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick
            />
        </Box>
    )
}
